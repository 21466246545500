/* Tips
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.tip {
  display: inline;
  position: relative;

  &:hover {
    text-decoration: none;

    &:before {
      border: solid;
      border-color: #333 transparent;
      border-width: .6rem .6rem 0 .6rem;
      bottom: 2.0rem;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      z-index: 99;
    }
    &:after {
      background-color: #333;
      border-radius: .3rem;
      bottom: 2.5rem;
      color: #fff;
      content: attr(data-hover);
	    font-size: 1.4rem;
	    line-height: 2.0rem;
      left: 50%;
      transform: translateX(-50%);
      padding: .5rem 1.5rem;
      position: absolute;
      z-index: 98;
      width: auto;
      white-space: nowrap;
      text-align: left;
    }
  }
}