/* Tables
–––––––––––––––––––––––––––––––––––––––––––––––––– */
table {
  width: 100%;

  tbody {

    tr {

      &:nth-child(odd) {
        background: #fafbfc;
      }

      &:last-child {

        td {
          border-bottom-width: 0;
        }
      }
    }
  }
  th,
  td {
    padding: 1.2rem 1.5rem;
    text-align: left;
  }
  th {
    font-weight: normal;
    border-bottom: .1rem solid #d3dbe2;

    &:hover {
      cursor: pointer;
    }
    &:empty:hover {
      cursor: default;
    }
  }
  td {

    .icon-image {
      float: left;
      display: inline-block;
      min-width: 2.4rem;
      min-height: 2.4rem;

      &:after {
        min-width: 2.4rem;
        min-height: 2.4rem;
        background-size: 24px 24px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */
        vertical-align: middle;
        display: inline-block;
    	}
    }
  }

  &.results {

    tr {

      td {
        padding-top: .8rem;
        padding-bottom: .8rem;
      }
    }
  }
  &.summary {

    th {
      padding: 0 0 2.0rem 0;
      border: 0;
      font-size: 1.2rem;
      font-weight: 600;
      text-transform: uppercase;

      &:hover {
        cursor: auto;
        background-color: transparent;
        color: inherit;
      }

      span {
        float: right;
        color: hsl(0,0%,44%);
        text-transform: none;
        font-weight: 300;
      }
    }

    tr {

      &:hover {
        background-color: transparent;
      }
      td {
        padding: .4rem 0;
        border: 0;

        &:first-child {
          width: 67%;
        }
        &:last-child {
          text-align: right;
          color: #95a7b7;
        }
      }
    }
  }
}


/* Pagination
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/*
.pagination {
  width: 100%;
  text-align: center;
  margin-top: 2.0rem;

  .button-group {
    display: inline-block;
    margin: 0;
  }
}
*/