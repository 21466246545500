/* Notice Banners
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.alert {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding: 1.6rem;
  color: #31373d;
  margin: -2.0rem -2.0rem 4.0rem -2.0rem;
  padding-left: 6.0rem;
  text-align: left;
  border-radius: 0 0 3px 3px;
  background-color: #f4f6f8;
  box-shadow: inset 0 3px 0 0 #637381, inset 0 0 0 0 transparent, 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);

  @media (min-width: 550px) {
    flex-direction: row;
    margin: 0 0 2.0rem 0;
  }
  .close {
    position: absolute;
    top: 1.3rem;
    right: 1.0rem;
    opacity: .75;
    width: 2.0rem;
    height: 2.0rem;
    background-image: icon(close, '%23637381');
    background-repeat: no-repeat;
    background-size: 20px 20px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */

    &:hover {
      opacity: 1;
    }
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 1.2rem;
    left: 1.2rem;
    width: 3rem;
    height: 3rem;
  }
  &:before {
    z-index: 1;
    border-radius: 50%;
    background: #dfe3e8;
  }
  &:after {
    z-index: 2;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020.03%2020%22%3E%3Ctitle%3Etext%3C%2Ftitle%3E%3Cpath%20d%3D%22M1.65%2C18.33A2.22%2C2.22%2C0%2C0%2C0%2C4%2C18.85L13%2C14%2C6%2C7%2C1.13%2C16a2.22%2C2.22%2C0%2C0%2C0%2C.52%2C2.33%22%20fill%3D%22%23fff%22%2F%3E%3Cpath%20d%3D%22M6.71%2C6.29a1%2C1%2C0%2C0%2C0-1.59.23l-4.87%2C9-.06.13a3.22%2C3.22%2C0%2C0%2C0%2C4.13%2C4.13l.13-.06%2C9-4.85a1%2C1%2C0%2C0%2C0%2C.23-1.59ZM3.58%2C17.93A1.22%2C1.22%2C0%2C0%2C1%2C2.05%2C16.4L6.24%2C8.66l5.1%2C5.1ZM15%2C4a1%2C1%2C0%2C0%2C1%2C1%2C1%2C1%2C1%2C0%2C0%2C0%2C2%2C0%2C1%2C1%2C0%2C0%2C1%2C1-1%2C1%2C1%2C0%2C0%2C0%2C0-2%2C1%2C1%2C0%2C0%2C1-1-1%2C1%2C1%2C0%2C0%2C0-2%2C0%2C1%2C1%2C0%2C0%2C1-1%2C1%2C1%2C1%2C0%2C0%2C0%2C0%2C2M1%2C4A1%2C1%2C0%2C0%2C1%2C2%2C5%2C1%2C1%2C0%2C0%2C0%2C4%2C5%2C1%2C1%2C0%2C0%2C1%2C5%2C4%2C1%2C1%2C0%2C0%2C0%2C5%2C2%2C1%2C1%2C0%2C0%2C1%2C4%2C1%2C1%2C1%2C0%2C0%2C0%2C2%2C1%2C1%2C1%2C0%2C0%2C1%2C1%2C2%2C1%2C1%2C0%2C0%2C0%2C1%2C4M19%2C16a1%2C1%2C0%2C0%2C1-1-1%2C1%2C1%2C0%2C0%2C0-2%2C0%2C1%2C1%2C0%2C0%2C1-1%2C1%2C1%2C1%2C0%2C0%2C0%2C0%2C2%2C1%2C1%2C0%2C0%2C1%2C1%2C1%2C1%2C1%2C0%2C0%2C0%2C2%2C0%2C1%2C1%2C0%2C0%2C1%2C1-1%2C1%2C1%2C0%2C0%2C0%2C0-2M12%2C9a1%2C1%2C0%2C0%2C0%2C.71-.29l1-1a1%2C1%2C0%2C1%2C0-1.41-1.41l-1%2C1A1%2C1%2C0%2C0%2C0%2C12%2C9m6.68.05-3%2C1A1%2C1%2C0%2C0%2C0%2C16.25%2C12l.06%2C0%2C3-1A1%2C1%2C0%2C0%2C0%2C18.75%2C9l-.06%2C0m-10-4.1a1%2C1%2C0%2C0%2C0%2C1.27-.63l1-3A1%2C1%2C0%2C0%2C0%2C9.05.68l-1%2C3a1%2C1%2C0%2C0%2C0%2C.63%2C1.26%22%20fill%3D%22%23212b36%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-size: 20px 20px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */
    background-position: center;
  }
  &.notice {
    background-color: #e0f5f5;
    box-shadow: inset 0 3px 0 0 #47c1bf, inset 0 0 0 0 transparent, 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);

    &:before {
      background: #b7ecec;
    }
    &:after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%3E%3Ctitle%3Etext%3C%2Ftitle%3E%3Cpath%20d%3D%22M2%2C3H13V7h6l-2%2C4%2C2%2C4H8V11H3%22%20transform%3D%22translate(0%200)%22%20fill%3D%22%23fff%22%2F%3E%3Cpath%20d%3D%22M16.1%2C11.45%2C17.38%2C14H9V12h4a1%2C1%2C0%2C0%2C0%2C1-1V8h3.38l-1.27%2C2.55a1%2C1%2C0%2C0%2C0%2C0%2C.9ZM2.69%2C4H12v6H4L2.69%2C4Zm15.43%2C7%2C1.77-3.55A1%2C1%2C0%2C0%2C0%2C19%2C6H14V3a1%2C1%2C0%2C0%2C0-1-1H2.25L2%2C.78a1%2C1%2C0%2C1%2C0-2%2C.43l4%2C18a1%2C1%2C0%2C0%2C0%2C2-.43H6L4.47%2C12H7v3a1%2C1%2C0%2C0%2C0%2C1%2C1H19a1%2C1%2C0%2C0%2C0%2C.89-1.45L18.12%2C11Z%22%20transform%3D%22translate(0%200)%22%20fill%3D%22%2300848e%22%2F%3E%3C%2Fsvg%3E");
    }
  }
  &.success {
    background-color: #e3f1df;
    box-shadow: inset 0 3px 0 0 #50b83c, inset 0 0 0 0 transparent, 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);

    &:before {
      background: #bbe5b3;
    }
    &:after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%3E%3Ctitle%3Etext%3C%2Ftitle%3E%3Ccircle%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%229%22%20fill%3D%22%23fff%22%2F%3E%3Cpath%20d%3D%22M10%2C0A10%2C10%2C0%2C1%2C0%2C20%2C10%2C10%2C10%2C0%2C0%2C0%2C10%2C0m0%2C18a8%2C8%2C0%2C1%2C1%2C8-8%2C8%2C8%2C0%2C0%2C1-8%2C8M12.29%2C7.29%2C9%2C10.59%2C7.71%2C9.29a1%2C1%2C0%2C1%2C0-1.44%2C1.39l0%2C0%2C2%2C2a1%2C1%2C0%2C0%2C0%2C1.41%2C0h0l4-4a1%2C1%2C0%2C0%2C0-1.39-1.44l0%2C0%22%20fill%3D%22%23108043%22%2F%3E%3C%2Fsvg%3E");
    }
  }
  &.warning,
  &.notification {
    background-color: #fcf1cd;
    box-shadow: inset 0 3px 0 0 #eec200, inset 0 0 0 0 transparent, 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);

    &:before {
      background: #ffea8a;
    }
    &:after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%3E%3Ctitle%3Etext%3C%2Ftitle%3E%3Ccircle%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%229%22%20fill%3D%22%23fff%22%2F%3E%3Cpath%20d%3D%22M10%2C0A10%2C10%2C0%2C1%2C0%2C20%2C10%2C10%2C10%2C0%2C0%2C0%2C10%2C0m0%2C18a8%2C8%2C0%2C1%2C1%2C8-8%2C8%2C8%2C0%2C0%2C1-8%2C8M10%2C5A1%2C1%2C0%2C0%2C0%2C9%2C6v4a1%2C1%2C0%2C0%2C0%2C2%2C0V6a1%2C1%2C0%2C0%2C0-1-1m0%2C8a1%2C1%2C0%2C1%2C0%2C1%2C1%2C1%2C1%2C0%2C0%2C0-1-1%22%20fill%3D%22%239c6f19%22%2F%3E%3C%2Fsvg%3E");
    }
  }
  &.error {
    background-color: #fbeae5;
    box-shadow: inset 0 3px 0 0 #ed6347, inset 0 0 0 0 transparent, 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);

    &:before {
      background: #fead9a;
    }
    &:after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%3E%3Ctitle%3Etext%3C%2Ftitle%3E%3Ccircle%20cx%3D%2210%22%20cy%3D%2210%22%20r%3D%229%22%20fill%3D%22%23fff%22%2F%3E%3Cpath%20d%3D%22M10%2C0A10%2C10%2C0%2C1%2C0%2C20%2C10%2C10%2C10%2C0%2C0%2C0%2C10%2C0Zm0%2C2.5a7.46%2C7.46%2C0%2C0%2C1%2C4.34%2C1.39L3.89%2C14.34A7.49%2C7.49%2C0%2C0%2C1%2C10%2C2.5Zm0%2C15a7.46%2C7.46%2C0%2C0%2C1-4.34-1.39L16.11%2C5.66A7.49%2C7.49%2C0%2C0%2C1%2C10%2C17.5Z%22%20fill%3D%22%23bf0711%22%2F%3E%3C%2Fsvg%3E");
    }
  }
  dl {
    flex: 1 1 auto;
    width: 100%;
    margin: 0;

    @media (min-width: 55.0rem) {
      margin-right: 2.0rem;
    }

    dt {
      font-size: 110%;
	    line-height: 120%;
	    font-weight: 600;
      margin: 0;
    }
    dd {
      line-height: 1.4;
      color: rgba(49,55,61,.85);
      margin: 1.0rem 0 0 0;
    }
  }
  .button,
  button,
  input[type="submit"],
  input[type="reset"],
  input[type="button"] {
    color: #31373d;
    text-decoration: none;
    border-color: rgba(49,55,61,.5);
    background: transparent;
    width: 100%;
    margin-top: 2.0rem;
    flex: 0 0 auto;
    box-shadow: none;

    @media (min-width: 550px) {
      width: auto;
      margin-top: 0;
    }

    &:hover,
    &:focus {
      background-color: rgba(49, 55, 61, 0.1);
      border-color: rgba(49,55,61,.85);
      color: rgba(49,55,61,.85) !important;
    }
  }
}
