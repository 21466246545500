/* Tags
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.tag {
  position: relative;
  display: inline-block;
  border-radius: .3rem;
  margin-right: .5rem;
  padding: 0 1rem;
  line-height: 2.8rem;
  vertical-align: middle;

  &.pending {
    padding-left: 2.5rem;
    background-repeat: no-repeat;
    background-size: 13px 13px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */
    background-position: .7rem .7rem;
  }
  &.blue {
    background-color: #B4E1FA;
    color: #001429;

    &.pending {
      background-image: icon(clock, 'rgb(0,20,41)');
    }
    &.remove a,
    &.collapsable a {
      background-image: icon(close, 'rgb(0,20,41)');
    }
  }
  &.green {
    background-color: #BBE5B3;
    color: #414F3E;

    &.pending {
      background-image: icon(clock, 'rgb(65,79,62)');
    }
    &.remove a,
    &.collapsable a {
      background-image: icon(close, 'rgb(65,79,62)');
    }
  }
  &.yellow {
    background-color: #FFEA8A;
    color: #595130;

    &.pending {
      background-image: icon(clock, 'rgb(89,81,48)');
    }
    &.remove a,
    &.collapsable a {
      background-image: icon(close, 'rgb(89,81,48)');
    }
  }
  &.red {
    background-color: #FEAF9A;
    color: #330101;

    &.pending {
      background-image: icon(clock, 'rgb(51,1,1)');
    }
    &.remove a,
    &.collapsable a {
      background-image: icon(close, 'rgb(51,1,1)');
    }
  }
  &.grey {
    background-color: #dfe3e8;
    color: #212b36;

    &.pending {
      background-image: icon(clock, 'rgb(33,43,54)');
    }
    &.remove a,
    &.collapsable a {
      background-image: icon(close, 'rgb(33,43,54)');
    }
  }
  &.orange {
    background-color: #FFC58B;
    color: #594430;

    &.pending {
      background-image: icon(clock, 'rgb(89,68,48)');
    }
    &.remove a,
    &.collapsable a {
      background-image: icon(close, 'rgb(89,68,48)');
    }
  }
  &.remove,
  &.collapsable {
    white-space: nowrap;
    padding-left: 1.0rem;
    padding-right: 3.5rem;

    a {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      right: 0;
      width: 2.8rem;
      height: 2.8rem;
      padding: 0 .8rem;
      background-size: 20px 20px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */
      background-repeat: no-repeat;
      background-position: center;
      transition: all ease .3s;

      &:hover {
        background-color: rgba(0,0,0,.1);
        border-top-right-radius: .3rem;
        border-bottom-right-radius: .3rem;
      }
      &:active,
      &:focus {
        background-color: rgba(0,0,0,.2);
        border-top-right-radius: .3rem;
        border-bottom-right-radius: .3rem;
      }
    }
  }
}
