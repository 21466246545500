/* Base Styles
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* NOTE
html is set to 62.5% so that all the REM measurements throughout Skeleton
are based on 10px sizing. So basically 1.5rem = 15px :) */

* {
  -ms-overflow-style: none;
}
html {
  height: 100%;
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}
body {
  box-sizing: border-box;
  height: 100%;
  font-size: 1.4em;
  line-height: 2.0rem;
  font-weight: 400;
  font-family: -apple-system, "BlinkMacSystemFont", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
  color: #1a1a1a;
  background-color: #f4f6f8;
  margin: 0;
  padding: 0;
  text-align: center;
  fill: currentColor;
  text-transform: initial;
  letter-spacing: initial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: #637381;
}
em {
  font-style: normal;
  color: hsl(0,0%,44%);
}
hr {
  margin: 0;
  border-width: 0;
  border-collapse: collapse;
  border-top: .1rem solid #dfe4e8;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: #007ace;
  transition: color .24s cubic-bezier(.64,0,.35,1);

  &:hover {
    color: #084e8a;
    outline: none;
  }
  &:focus {
    color: #084e8a;
    outline: none;
  }
  &:active {
    color: #084e8a;
    outline: none;
  }
}
svg,
path {
  fill: currentColor;
}



/* Lists
–––––––––––––––––––––––––––––––––––––––––––––––––– */
ul {
  list-style: disc outside;
}
ol {
  list-style: decimal outside;
}
ol, ul {
  padding-left: 0;
  margin-top: 0;
  margin-left: 2.0rem;
  margin-bottom: 0;
}
ul ul,
ul ol,
ol ol,
ol ul {
  margin: 0 0 0 3rem;
}
li {
  color: #637381;
}


/* Code
–––––––––––––––––––––––––––––––––––––––––––––––––– */
code {
  padding: .2rem .5rem;
  margin: 0 .2rem;
  white-space: nowrap;
  background: #F1F1F1;
  border: .1rem solid #E1E1E1;
  border-radius: .4rem;
}
pre > code {
  display: block;
  padding: 1rem 1.5rem;
  margin: 0;
  overflow: auto;
}
main {
  position: relative;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}
section {
  display: block;
  width: 100%;
  max-width: 1036px;
  text-align: left;
  margin: 0 auto;

  @media (min-width: 550px) {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex-direction: row;
  }
  &+section {
    padding-top: 2rem;
    border-top: .1rem solid #d3dbe2;
  }
  aside {
    flex: 1;
    padding: 0 2.0rem;
    box-sizing: border-box;

    @media (min-width: 550px) {
      flex: 1 0 24.0rem;
    }

    h2 {
      margin: 0;
	    padding: 2.0rem 0;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 1.6em;
    }
    p {
      color: hsl(0,0%,44%);
      line-height: 1.4;
    }
  }
  article {
    display: flex;
    flex: 1;
    flex-direction: column;


    @media (min-width: 550px) {
      flex: 2 1 48.0rem;
      flex-direction: row;
	    margin: 0 2.0rem;
    }
  }
  .card {
    display: inline-block;
    width: 100%;
    color: #1a1919;
    box-sizing: border-box;
    margin-bottom: 0;
    padding: 2.0rem;
    background-color: #ffffff;
    border-radius: .3rem;

    ~ h1:first-of-type,
    ~ h2:first-of-type,
    ~ h3:first-of-type,
    ~ h4:first-of-type,
    ~ h5:first-of-type,
    ~ h6:first-of-type {
      margin-top: 0;
    }

    @media (min-width: 550px) {
      //border-radius: .3rem;
      box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);
      margin-bottom: 2.0rem;
    }

    &.secondary {
      background-color: #ebeef0;
    }
    &.has-sections {
      padding: 0;
    }
    .card-section {
      padding: 2.0rem;

      ~ h1:first-of-type,
      ~ h2:first-of-type,
      ~ h3:first-of-type,
      ~ h4:first-of-type,
      ~ h5:first-of-type,
      ~ h6:first-of-type {
        margin-top: 0;
      }
    }
    p {
      color: hsl(0,0%,10%);
    }
  }
}


/* Clearing
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* Self Clearing Goodness */
main:after,
.row:after,
.cf {
  content: "";
  display: table;
  clear: both;
}
