/* Forms
–––––––––––––––––––––––––––––––––––––––––––––––––– */
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  position: relative;
  padding: .5rem 1rem;
  background-color: #fff;
  border: .1rem solid #c4cdd5;
  border-radius: .3rem;
  color: #31373d;
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  min-width: 7.5rem;
  vertical-align: baseline;
  height: auto;
  margin: 0;
  max-width: 100%;
  font-family: -apple-system, "BlinkMacSystemFont", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
  box-shadow: 0 0 0 1px transparent, 0 1px 0 0 rgba(22,29,37,.05);
  box-sizing: border-box;
  transition: box-shadow .2s cubic-bezier(.64,0,.35,1);

  &:focus {
    border-color: #5c6ac4;
    box-shadow: inset 0 0 0 0 transparent, 0 0 0 .1rem #5c6ac4;
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #F4F6F8;
    border: .1rem solid #DFE4E8;
    color: #C4CDD5;
  }
  .error &:not(.no-error) {
    border-color: #ed6347;
    background-color: #fbeae5;

    &:focus {
      border-color: #5c6ac4;
    }
  }
  &[type=search] {
    padding-left: 3.0rem;
    background-image: icon(search, 'rgb(149,167,183)');
    background-repeat: no-repeat;
    background-size: 18px 18px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */
    background-position: top .8rem left .8rem;
  }
}
::-webkit-input-placeholder {
   color: hsl(0, 0%, 62%);
}
:-moz-placeholder { /* Firefox 18- */
   color: hsl(0, 0%, 62%);
}
::-moz-placeholder {  /* Firefox 19+ */
   color: hsl(0, 0%, 62%);
}
:-ms-input-placeholder {
   color: hsl(0, 0%, 62%);
}
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="radio"],
input[type="checkbox"],
textarea,
select {
  appearance: none;
}
input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
  min-height: 3.4rem;
}
select {
  padding-right: 3.2rem;
  background-image: icon(select-arrows, 'rgb(99,115,129)');
  background-size: 21px 21px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */
  background-repeat: no-repeat;
  background-position: right 0.7rem top 0.7rem;
  box-shadow: 0 0 0 1px transparent, 0 1px 0 0 rgba(22,29,37,.05);

  &[multiple] {
    padding-right: 1rem;
    background-image: none;
  }
  &:disabled {
    background-image: icon(select-arrows, 'rgb(145,158,171)');
    box-shadow: none;
  }
  &::-ms-expand {
    display: none;
  }
}
textarea {
  min-height: 6.5rem;
  padding-top: .6rem;
  padding-bottom: .6rem;
}
label,
legend {
  display: block;
  font-weight: 600;
}
fieldset {
  padding: 0;
  border-width: 0;
}
input[type="checkbox"],
input[type="radio"] {
  position: relative;
  display: inline-block;
  height: 1.6rem;
  width: 1.6rem;
  border: .1rem solid #c4cdd5;
  background: linear-gradient(180deg,#fff,#f9fafb);
  box-shadow: 0 0 0 1px transparent, 0 1px 0 0 rgba(22,29,37,.05);
  margin: 0 1rem 0 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align: text-bottom;

  &:checked:after {
    content: '';
    position: absolute;
  }
  &:active {
    border: .1rem solid #5c6ac4;
    box-shadow: 0 0 0 .1rem #5c6ac4;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: #fafbfc;
    border: .1rem solid #ebeef0;
    box-shadow: none;
    color: #c3cfd8;
  }
  .error &:not(.no-error) {
    border-color: #ed6347;
    background-color: #fbeae5;

    &:active {
      border: .1rem solid #5c6ac4;
      box-shadow: 0 0 0 .1rem #5c6ac4;
    }
  }
}

input[type="checkbox"] {
  border-radius: .3rem;

  &:disabled:checked:after {
    background-image: icon(checkmark, 'rgb(196,205,213)');
  }
  &:checked:after {
    width: 1.2rem;
    height: 1.2rem;
    background-image: icon(checkmark, 'rgb(92,106,196)');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12px 12px; /* IE requires pixels. Using cover/contain/rem values cause blurry edges */
    top: .1rem;
    left: .1rem;
  }
}
input[type="radio"] {
  border-radius: .8rem;

  &:disabled:checked:after {
    background-color: rgb(196,205,213);
  }
  &:checked:after {
    width: .8rem;
    height: .8rem;
    background-color: rgb(92,106,196);
    border-radius: .5rem;
    top: .3rem;
    left: .3rem;
  }
}
label {
  display: block;
  color: #212B35;
  font-weight: normal;

  &.error,
  .error &:not(.no-error) {
    color: #bf0711;
  }
  .label-cta {
    float: right;
  }
}
.input-group {
  display: flex;
  align-items: center;

  select,
  input,
  .append,
  .button,
  button {
    z-index: 10;
    border-left-width: 0;

    &:first-child {
      border-left-width: .1rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }
  }
  .append {
    flex: 0 0 auto;
    line-height: 2.4rem;
    padding: .5rem 1rem;
    border: .1rem solid #c4cdd5;
    border-radius: .3rem;
    z-index: 10;
    box-shadow: 0 0 0 1px transparent, 0 1px 0 0 rgba(22,29,37,.05);
  }
  button,
  .button,
  select,
  input {

    &:active {
      z-index: 20;
    }
  }
  select + .append,
  input + .append {
    border-left-width: 0;
  }
  .button,
  button {
    flex: 0 0 auto;
  }
}
.side-elements {
  display: flex;
  align-items: center;

  label {
    flex: 0 0 auto;
    margin: 0 1rem 0 0;
    white-space: nowrap;
  }
  button,
  .button {
    flex: 0 0 auto;
    margin-left: 1rem;
  }
}
