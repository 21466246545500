/* Typography
–––––––––––––––––––––––––––––––––––––––––––––––––– */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 2rem;
  font-weight: 400;
}
h1 { font-size: 2.4rem; line-height: 3.2rem; }
h2 { font-size: 2.1rem; line-height: 2.8rem; }
h3 { font-size: 1.8rem; line-height: 2.4rem; }
h4 { font-size: 2.0rem; line-height: 2.4rem; }
h5 { font-size: 1.6rem; line-height: 2.4rem; font-weight: 600; }
h6 { font-size: 1.2rem; line-height: 2.0rem; font-weight: 800; text-transform: uppercase; letter-spacing: 0.04em}

/* Larger than phablet */
@media (min-width: 550px) {
  h1 { font-size: 3.3rem; line-height: 4.0rem; }
  h2 { font-size: 2.6rem; line-height: 3.6rem;}
  h3 { font-size: 2.0rem; line-height: 2.8rem;}
}
