/* Tabs
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.tabs {
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  box-shadow: inset 0 -1px 0 0 #dfe3e8;

  li {
    flex: 0 0 auto;
    padding: 0 1.6rem;

    a {
      display: inline-block;
      font-size: 1.4rem;
      line-height: 3.6rem;
      text-align: center;
      cursor: pointer;
      position: relative;
      white-space: nowrap;
      color: #637381;
      padding-top: .3rem;
      border-bottom: .3rem solid transparent;

      &:hover {
        outline: none;
        color: #212b36;
        border-color: #dfe3e8;
        text-decoration: none;
      }
    }

    &.active,
    &:first-child:last-child {

      a {
        color: #212b36;
        cursor: default;
        text-decoration: none;
        border-color: #5c6ac4
      }
    }
  }
}
