/* Utilities
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.full-width {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}
.max-full-width {
  max-width: 100%;
  box-sizing: border-box;
}
.align-center {
  text-align: center;
}
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.highlight-warning {
  background: #FCF1CD;
  color: #ED6347;
}
.blue {
  color: #007ACE;
}
.green {
  color: #50B83C;
}
.yellow {
  color: #EEC200;
}
.red {
  color: #ED6347;
}
.grey {
  color: #DFE4E8;
}
.lightblue {
  color: #B4E1FA;
}
.orange {
  color: #F49342;
}
.emphasis {
  font-weight: 600;
  color: hsl(0,0%,10%);
}
.subdued {
  font-weight: normal;
  color: hsl(0,0%,44%);
}
.hide-scrollbars {

  ::-webkit-scrollbar {
    display: none !important;
    width: 10px !important;
    height: 0 !important;
  }
  ::-webkit-scrollbar-thumb {
      background-color: rgba(0,0,0,0);
  }
}
.overflow-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
