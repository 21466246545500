/* Zero State
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.zero-state {
  padding-top: 5.0rem;
  text-align: center;
  box-sizing: border-box;
  min-height: 400px;

  h1 {
    width: 100%;
    margin-bottom: 1.8rem;

    &+* {
      width: 100%;
      margin-bottom: 5.0rem;
    }
  }
  article {
    display: block;

    @media (min-width: 550px) {
      button {
        width: auto;
      }
    }
  }
}
